<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张磊（zhanglei1204@jd.com）
 * @Date: 2021-05-16 20:39:14
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-17 01:21:04
 * @FilePath: /官网迭代/src/views/admin/teacher/sports.vue
-->
<template>
  <div class="sports_page">
    <div class="tabTit">
      <span class="tips"
        >小班请选择难度等级1进行测试，中班请选择难度等级2进行测试，大班请选择难度等级3进行测试</span
      >
      <span class="text">运动测试</span>
    </div>
    <div class="custTab" v-loading="loading">
      <table class="layui-table" lay-skin="nob">
        <thead>
          <tr>
            <th>测试项目</th>
            <th>测试等级</th>
            <th>测试名称</th>
            <th>学期初状态</th>
            <th>学期末状态</th>
            <th>教案</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in toList" :key="index">
            <!-- <td rowspan="3" v-show="(index + 1) % 3 == 1" align="center">
              {{ item.testingSports.testNo }}.{{ item.testingSports.title }}
            </td> -->
            <td rowspan="1" align="center">
              {{ item.testingSports.testNo }}.{{ item.testingSports.title }}
            </td>
            <td>难度等级{{ item.testingSports.testlevel }}</td>
            <td>{{ item.testingSports.name }}</td>
            <td>{{ item.finish ? "已完成" : "未完成" }}</td>
            <td>{{ item.open ? "已完成" : "未完成" }}</td>
            <td>
              <div class="btn-play tab" @click="playVideo(item.testingSports)">
                播放
              </div>
            </td>
            <td class="text-center">
              <div class="btn btn-start tab" @click="toTestCheck(item)">
                开始测试
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "sports",
  components: {},
  data() {
    return {
      list: [],
      toList: [],
      loading: true,
    };
  },
  created() {},
  mounted() {
    this.getYDList();
  },
  methods: {
    getYDList() {
      console.log(this.$route.query);
      let data = {
        semesterId: this.$store.state.userInfo.semesterId,
        gradeId: this.$route.query.id,
        isEnd: 0,
        schoolId: this.$route.query.schoolId,
        gradeType: this.$route.query.type,
      };
      this.api.admin.findSportsTest(data).then((res) => {
        if (res.flag) {
          this.loading = false;
        }
        this.list = res.data;
        switch (this.$route.query.type) {
          case "0": //大班
            this.list.forEach((item) => {
              if (item.testingSports.testlevel === "3") {
                this.toList.push(item);
              }
            });
            break;
          case "1": //中班
            this.list.forEach((item) => {
              if (item.testingSports.testlevel === "2") {
                console.log(item);

                this.toList.push(item);
              }
            });
            break;
          case "2": //小班
            this.list.forEach((item) => {
              if (item.testingSports.testlevel === "1") {
                this.toList.push(item);
              }
            });
            break;
        }
      });
    },
    playVideo(item) {
      console.log(item);
      this.$router.push({
        path: "/sports_info",
        query: {
          id: item.id,
          isSports: true,
          gradeId: this.$route.query.id,
          testlevel: item.testlevel,
        },
      });
    },
    toTestCheck(item) {
      console.log(item, "1111111111111111");
      // this.$router.push({
      //   path: "exerciseTestB",
      //   query: {
      //     gradeId: this.$route.query.id,
      //     isSports: true,
      //     sportId: item.testingSports.id,
      //     testlevel: item.testingSports.testlevel,
      //   },
      // });
    },
    // 0 大班 1 中班 2 小班
    // level 1 小班 level 2 中班  level 3 大班
  },
};
</script>
<style lang="scss" scoped>
.sports_page {
  width: 100%;
  .tabTit {
    min-height: 0.9rem;
    box-sizing: border-box;
    /* text-align: right; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .tabTit .tips {
    font-size: 0.2rem;
    /* position: absolute;
    left: 0; */
  }

  .tabTit .text {
    line-height: 0.3rem;
    font-weight: 700;
    color: #cb1b31;
    font-size: 0.3rem;
    /* padding-right: .5rem;
    text-align: right;
    float: right; */
  }

  .tabTit .searchdiv {
    display: flex;
  }

  .tabTit .searchdiv .btn-search {
    margin-right: 0.2rem;
  }

  .tabTit .btn-add,
  .tabTit .btn-blue {
    padding: 0rem 0.4rem;
    height: 38px;
    line-height: 38px;
    font-size: 0.22rem;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    border: none;
  }

  .tabTit .btn-add {
    background: #cb1b31;
    color: #fff;
  }

  .tabTit .btn-blue {
    background: #228cec;
    color: #fff;
  }
  .custTab {
    /* padding: 0rem .5rem; */
    position: relative;
    margin-bottom: 0.4rem;
  }

  .custTab .layui-table {
    padding: 0;
    margin: 0;
    th,
    td {
      text-align: center;
    }
  }

  .custTab thead {
    position: relative;
  }

  .custTab thead::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #f5d1d6;
    height: 0.56rem;
  }

  .custTab thead th {
    background: #cc1a30;
    line-height: 0.3rem;
    font-weight: 700;
    color: #fff;
    font-size: 0.26rem;
    padding: 0.15rem;
  }

  .custTab tbody {
    border-bottom: solid 3px #cc1a30;
  }

  .custTab tbody tr {
    border-bottom: solid 1px #cb1b31;
  }

  .custTab tbody td {
    color: #3e3e3e;
    font-size: 0.22rem;
    line-height: 0.3rem;
    padding: 0.15rem;
  }

  .custTab tbody tr td:last-child {
    padding-right: 0;
  }

  .custTab .btn-start {
    width: 1.28rem;
    height: 0.38rem;
    line-height: 0.38rem;
    background: #cc1a30;
    color: #fff;
    display: inline-block;
    font-size: 0.22rem;
    text-align: center;
    transition: all 0.2s;
    float: right;
  }
  .custTab .btn-start:hover {
    background: #cc1a30;
  }

  .custTab .btn-play {
    background: #cc1a30 url("../../../assets/img/lesson/play2.png") 0.2rem
      center no-repeat;
    background-size: 0.26rem;
    height: 0.4rem;
    line-height: 0.4rem;
    padding-left: 0.6rem;
    width: 0.7rem;
    display: inline-block;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 0.22rem;
  }
}
</style>
